import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { RootNode } from '@sick-curie/dashboard-builder';

import { switchMap } from 'rxjs';
import { ENVIRONMENT, Environment } from 'sick-environment';

import { BaseApiService } from './base-api.service';

interface DashboardMetaData {
  dashboardId: string;
  versionName: string;
  instanceId: string;
  ownerId: string;
  childrenDashboardIds?: Array<string>;
  parentDashboardId?: string;
  dashboardTitle?: string;
}

export interface DashboardItem extends DashboardMetaData {
  dashboard: RootNode;
}

@Injectable({
  providedIn: 'root',
})
export class DashboardApiService {
  private baseApiService: BaseApiService;

  constructor(@Inject(ENVIRONMENT) private env: Environment, private httpClient: HttpClient) {
    this.baseApiService = new BaseApiService(this.env.apiUrl + '/dashboards', this.httpClient);
  }

  loadByInstance(instanceId: string) {
    return this.baseApiService.list<DashboardMetaData>({ instanceId: instanceId }).pipe(
      switchMap((dashboards) => {
        const dashboardId: string | undefined =
          dashboards.find((dashboard) => !dashboard.parentDashboardId || dashboard.parentDashboardId === '')
            ?.dashboardId ?? dashboards[0].dashboardId;
        return this.read(dashboardId);
      })
    );
  }

  read(dashboardId: string) {
    return this.baseApiService.read<DashboardItem>(dashboardId);
  }

  update(dashboardId: string, dashboard: RootNode) {
    const payload = JSON.stringify(dashboard);
    return this.baseApiService.update<string, DashboardItem>(dashboardId, payload);
  }
}
